var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "content table-responsive table-full-width" }, [
      _c("table", { staticClass: "table table-sm" }, [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v("Locatie naam")]),
            _c("th", [_vm._v("Adres")]),
            _c("th", [_vm._v("Aantal")]),
            _c("th", [_vm._v("Aangevraagd op")]),
            _c("th", [_vm._v("Aangevraagd door")]),
            _vm.sent ? _c("th", [_vm._v("Verzonden op")]) : _c("th")
          ])
        ]),
        _c(
          "tbody",
          [
            _vm._l(_vm.requests, function(request) {
              return _c("tr", [
                _c("td", [_vm._v(_vm._s(request.location_name))]),
                _c("td", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(request.street) +
                      " " +
                      _vm._s(request.house_no) +
                      " "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n                    " +
                      _vm._s(request.postal_code) +
                      " " +
                      _vm._s(request.city) +
                      "\n                "
                  )
                ]),
                _c("td", [_vm._v(_vm._s(request.amount))]),
                _c("td", [
                  _vm._v(_vm._s(_vm._f("toDate")(request.created_at)))
                ]),
                _c("td", [
                  _vm._v(
                    _vm._s(request.requester_name) +
                      " (" +
                      _vm._s(request.requester_email) +
                      ")"
                  )
                ]),
                _vm.sent
                  ? _c("td", [
                      _vm._v(_vm._s(_vm._f("toDate")(request.send_at)))
                    ])
                  : _c("td", [
                      _c("i", {
                        staticClass: "fa-2x fa fa-envelope text-primary",
                        on: {
                          click: function($event) {
                            return _vm.send(request)
                          }
                        }
                      })
                    ])
              ])
            }),
            _vm.requests.length === 0
              ? _c("tr", [
                  _c(
                    "td",
                    { staticClass: "text-center", attrs: { colspan: "7" } },
                    [_vm._v("Geen aanvragen")]
                  )
                ])
              : _vm._e()
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }