var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.pagination.total > 0
        ? _c("h4", { staticClass: "d-flex justify-content-around" }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t("components.location_list.title", {
                    locations: _vm.pagination.total
                  })
                ) +
                "\n    "
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-outline-primary",
                attrs: { type: "button" },
                on: { click: _vm.exportData }
              },
              [
                _vm._v("\n      export "),
                _vm.exporting
                  ? _c("i", { staticClass: "fa fa-spinner fa-spin" })
                  : _vm._e()
              ]
            )
          ])
        : _vm._e(),
      _c(
        "item-list",
        {
          attrs: {
            rows: _vm.items,
            loading: _vm.loading,
            searching: false,
            route: "locations.show",
            value: ""
          },
          on: { search: _vm.search, lazy: _vm.loadLazy },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("td", { staticStyle: { width: "40px" } }, [
                    _c("div", { staticClass: "round-image" }, [
                      _c("img", {
                        attrs: {
                          src: (row.images || {}).thumbnail || _vm.placeholder,
                          alt: "location"
                        }
                      })
                    ])
                  ]),
                  _c("td", [_vm._v(_vm._s(row.location.name))]),
                  _c("td", [_vm._v(_vm._s(row.location.city))]),
                  _c("td", [_vm._v(_vm._s(row.spots.length))]),
                  _vm.isMultisport
                    ? _c("td", [
                        _vm._v(
                          _vm._s(
                            Array.from(
                              new Set(
                                (row.spots || []).map(function(s) {
                                  return s.sport
                                })
                              )
                            ).join(", ")
                          )
                        )
                      ])
                    : _vm._e(),
                  _c("td", [_vm._v(_vm._s(_vm._f("toDate")(row.created_at)))])
                ]
              }
            }
          ]),
          model: {
            value: _vm.searchvalue,
            callback: function($$v) {
              _vm.searchvalue = $$v
            },
            expression: "searchvalue"
          }
        },
        [
          _c("template", { slot: "header" }, [
            _c("th"),
            _c("th", [_vm._v("Name")]),
            _c("th", [_vm._v("City")]),
            _c("th", [_vm._v("Spots")]),
            _vm.isMultisport ? _c("th", [_vm._v("Sports")]) : _vm._e(),
            _c("th", [_vm._v("Created")])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }