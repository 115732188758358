var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", {
      directives: [
        {
          name: "t",
          rawName: "v-t",
          value:
            "views.dashboard.children.sport_admin.children.locations.index.title",
          expression:
            "'views.dashboard.children.sport_admin.children.locations.index.title'"
        }
      ]
    }),
    _c("h2", {
      directives: [
        {
          name: "t",
          rawName: "v-t",
          value:
            "views.dashboard.children.sport_admin.children.locations.index.sub",
          expression:
            "'views.dashboard.children.sport_admin.children.locations.index.sub'"
        }
      ]
    }),
    _c(
      "div",
      { staticClass: "card tabbed" },
      [
        _c("div", { staticClass: "card-header" }, [
          _c("ul", { staticClass: "nav nav-tabs card-header-tabs" }, [
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      to: { name: "locations.index" },
                      exact: true,
                      "active-class": "active"
                    }
                  },
                  [_vm._v("Locaties\n          ")]
                )
              ],
              1
            ),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      to: { name: "spots.index", query: { type: "active" } },
                      exact: true,
                      "active-class": "active"
                    }
                  },
                  [_vm._v("Actieve spots\n          ")]
                )
              ],
              1
            ),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      to: { name: "spots.index", query: { type: "inactive" } },
                      exact: true,
                      "active-class": "active"
                    }
                  },
                  [_vm._v("Inactieve spots\n          ")]
                )
              ],
              1
            ),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      to: { name: "spots.index", query: { type: "temporary" } },
                      exact: true,
                      "active-class": "active"
                    }
                  },
                  [_vm._v("Tijdelijke spots\n          ")]
                )
              ],
              1
            )
          ])
        ]),
        _c("router-view")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }