<template>
  <div>
    <h4 v-if="pagination.total > 0" class="d-flex justify-content-around">
      {{$t('components.location_list.title', {locations: pagination.total})}}
      <button type="button" class="btn btn-sm btn-outline-primary" @click="exportData">
        export <i class="fa fa-spinner fa-spin" v-if="exporting"></i>
      </button>
    </h4>
    <item-list
      :rows="items"
      :loading=loading
      :searching=false
      @search="search"
      v-model="searchvalue"
      route="locations.show"
      value=""
      @lazy="loadLazy">
      <template slot="header">
        <th></th>
        <th>Name</th>
        <th>City</th>
        <th>Spots</th>
        <th v-if="isMultisport">Sports</th>
        <th>Created</th>
      </template>

      <template v-slot="{row}">
        <td style="width:40px">
          <div class="round-image">
            <img :src="(row.images ||{}).thumbnail || placeholder" alt="location">
          </div>
        </td>
        <td>{{row.location.name}}</td>
        <td>{{row.location.city}}</td>
        <td>{{row.spots.length}}</td>
        <td v-if="isMultisport">{{Array.from(new Set((row.spots || []).map(s => s.sport))).join(', ')}}</td>
        <td>{{row.created_at|toDate}}</td>
      </template>
    </item-list>
  </div>
</template>

<script>
  import Component, {mixins} from 'vue-class-component/lib/index';

  import { adminBackend } from '@/api';
  import api from '@/data/Backend';
  import MultiSportMixin from '@/mixins/MultiSport.mixin';
  import LazyLoading from '@/mixins/LazyLoading.mixin';
  import ItemList from 'sportvue/src/components/ItemList';

  import {getExport} from '@/data/utils';
  import placeholder from 'sportvue/src/assets/placeholders/location-placeholder.svg';

  @Component({components: {
      ItemList,
  }})
  export default class Locations extends mixins(LazyLoading, MultiSportMixin) {
    placeholder = placeholder;
    exporting = false;
    searchvalue = '';

    mounted() {
      this.getData();
    }

    exportData() {
      this.exporting = true;

      getExport(
        adminBackend(this.$router),
        'locations/csv',
        'locations.csv',
      ).finally(() => {
        this.exporting = false;
      });
    }

    search(value) {
      this.items = [];

      this.pagination = {
        total: 0,
        per_page: 50,
        current_page: 1,
      };

      this.searchvalue = value;

      return this.getData();
    }

    getData(newPage = undefined) {
      this.loading = true;

      this.loadData(api().getSportLocations(newPage || this.page, this.pagination.per_page, this.query));
    }
  }
</script>

<style scoped lang="scss">

</style>
