<template>
  <div>
    <div class="col-lg-7">
      <statistic-card-group :columns="2" :statistics="statistics" :statistics_order="statistics_order"></statistic-card-group>
    </div>

    <session-chart class="col-12 my-5"></session-chart>
    <div class="col-12">
      locaties:
      <heat-map></heat-map>
    </div>
<!--      <location-map :items="locations" @search="search" class="col-xs-12"></location-map>-->
  </div>
</template>

<script>
  import LocationMap from '@/components/widgets/LocationMap'
  import StatisticCardGroup from 'sportvue/src/components/StatisticCardGroup'
  import StatisticCard from 'sportvue/src/components/StatisticCard'
  import SessionChart from '@/components/SessionChart';
  import HeatMap from '@/components/HeatMap';

  import { backend} from '../api'

  import api from '@/data/Backend';

  export default {
    components: { HeatMap, LocationMap, StatisticCardGroup, StatisticCard, SessionChart },
    data() {
      return {
        statistics_order: [
          'user_count',
          'users_today_count',
          'users_active_count',
          'locations_count',
          'spots_count',
          'sessions_count',
          'sessions_today_count'
        ],

        stats: {},
        locations: [],
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      getData() {
        api().getStats().then((result) => {
          this.stats = result;
        });
      },
      search(within) {
        backend(this.$router).get(`/canteens?include=location&bounds=${within}`).then((response) => {
          this.locations = response.data.canteens;
        });
      }
    },
    computed: {
      statistics() {
        const stats = this.stats;

        return {
          user_count: {value: stats.user_count, unit: 'gebruikers', title: 'Totaal geregistreerd', faicon: 'fa-users'},
          users_today_count: {
            value: stats.users_today_count,
            unit: 'gebruikers',
            title: 'Vandaag geregistreerd',
            faicon: 'fa-user'
          },
          users_active_count: {
            value: stats.users_active_count,
            unit: 'gebruikers',
            title: 'Met sessies',
            faicon: 'fa-user'
          },
          locations_count: {value: stats.locations_count, unit: 'locaties', faicon: 'fa-building'},
          spots_count: {value: stats.spots_count, unit: 'spots', faicon: 'fa-building'},
          sessions_count: {
            value: stats.sessions_count,
            unit: 'speelmomenten',
            title: 'Totaal aangemaakt',
            faicon: 'fa-history'
          },
          sessions_today_count: {
            value: stats.sessions_today_count,
            unit: 'speelmomenten',
            title: 'Vandaag aangemaakt',
            faicon: 'fa-history'
          },
        };
      },
    }
  }
</script>
<style lang="scss">

</style>
