<template>
  <div class="content">
    <h1>Bazen</h1>
    <item-list
      :rows="items"
      :loading="loading"
      route="profiles.show"
      @search="search"
      v-model="searchvalue"
      value=""
      @lazy="loadLazy">
      <template slot="header">
        <th>Naam</th>
        <th>Punten</th>
        <th>Email</th>
        <th>Geregistreerd op</th>
        <th>Laatste speelmoment</th>
        <th>Aantal speelmomenten</th>
      </template>
      <template v-slot="{row}">
        <td><profile-set :profile="row"></profile-set></td>
        <td>{{row.points}}</td>
        <td>{{row.user.email}}</td>
        <td>{{row.created_at|toDate}}</td>
        <td>{{row.last_session_at|toDate}}</td>
        <td>{{row.total_sessions}}</td>
      </template>
    </item-list>
  </div>
</template>

<script>
  import ProfileSet from 'sportvue/src/components/elements/ProfileSet.vue';
  import api from '@/data/Backend';
  import LazyLoading from '@/mixins/LazyLoading.mixin';

  import ItemList from '_sportvue/components/ItemList';

  export default {
    data() {
      return {
        searchvalue: '',
        query: undefined,
      };
    },

    mounted() {
      this.getData();
    },

    beforeRouteUpdate(to, from, next) {
      next();
      this.getData();
    },

    computed: {
    },

    methods: {
      getData(newPage = undefined) {
        this.loading = true;

        this.loadData(api().getProfiles(newPage || this.page, this.pagination.per_page, this.query));
      },

      search(value) {
        this.items = [];

        this.pagination = {
          total: 0,
          per_page: 50,
          current_page: 1,
        };

        this.query = value;
        return this.getData();
      },
    },
    components: {
      ProfileSet,
      ItemList,
    },
    mixins: [LazyLoading],

    filters: {
      // timeAgo
    },
  };
</script>

<style scoped lang="scss">

</style>
