var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "item-list",
    {
      attrs: { rows: _vm.items, loading: _vm.loading, value: "" },
      on: { lazy: _vm.loadLazy },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function(ref) {
            var row = ref.row
            return [
              _c("td", [
                _vm._v(
                  _vm._s(
                    row.code
                      .toString()
                      .match(/.{1,2}/g)
                      .join(" ")
                  )
                )
              ]),
              _c(
                "td",
                [
                  row.canteen
                    ? [
                        _vm._v(
                          "\n        " + _vm._s(row.canteen.name) + "\n      "
                        )
                      ]
                    : row.name
                    ? [_vm._v(_vm._s(row.name))]
                    : _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Onbekend")
                      ])
                ],
                2
              ),
              _c("td", [_vm._v(_vm._s(row.accessibility))]),
              _c("td", [_vm._v(_vm._s(row.quality))]),
              _vm.isMultisport
                ? _c("td", [_vm._v(_vm._s(row.sport))])
                : _vm._e(),
              _c("td", [_vm._v(_vm._s(row.session_count))]),
              _c("td", [_c("qr-link", { attrs: { code: row.code } })], 1)
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "header" }, [
        _c("th", [_vm._v("Code")]),
        _c("th", [_vm._v("Naam")]),
        _c("th", [_vm._v("Bereikbaarheid")]),
        _c("th", [_vm._v("Kwaliteit")]),
        _vm.isMultisport ? _c("th", [_vm._v("Sport")]) : _vm._e(),
        _c("th", [_vm._v("Sessions")]),
        _c("th")
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }