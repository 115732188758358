<template>
  <div class="card text-center tabbed">
    <div class="card-header">
      <ul class="nav nav-tabs card-header-tabs">
        <li class="nav-item">
          <router-link
            :to="{name: 'stickers.index', params: {type: 'requested'}}"
            :exact="true"
            active-class="active"
            class="nav-link">Aangevraagd
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{name: 'stickers.index', params: {type: 'sent'}}"
            :exact="true"
            active-class="active"
            class="nav-link">Verzonden
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{name: 'stickers.batches'}"
            active-class="active"
            class="nav-link">Batches
          </router-link>
        </li>
      </ul>
    </div>
    <div class="card-body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>
</style>
