<template>
  <div>
Spot
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss">

</style>
