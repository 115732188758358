var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.session.id
    ? _c(
        "div",
        [
          _c(
            "show-header",
            {
              attrs: {
                image: (_vm.location.images || {}).original,
                title: _vm._f("toHumanDayDate")(_vm.session.started_at)
              }
            },
            [
              _c("ul", [
                _vm.spot.name
                  ? _c("li", { attrs: { title: "spot" } }, [
                      _vm._v(_vm._s(_vm.spot.name))
                    ])
                  : _vm._e(),
                _c(
                  "li",
                  { attrs: { title: "location" } },
                  [
                    _vm.location
                      ? [_vm._v(_vm._s(_vm.location.name))]
                      : [_c("i", [_vm._v("Onbekende Locatie")])]
                  ],
                  2
                ),
                _c("li", { attrs: { title: "start - end" } }, [
                  _vm._v(
                    _vm._s(_vm._f("toTime")(_vm.session.started_at)) +
                      "\n        "
                  ),
                  _vm.session.ends_at
                    ? _c("span", [
                        _vm._v(
                          " - " +
                            _vm._s(_vm._f("toTime")(_vm.session.ends_at)) +
                            " uur"
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ]
          ),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "card col-12" }, [
              _c("div", { staticClass: "card-body" }, [
                _c(
                  "div",
                  { staticClass: "row justify-content-around" },
                  _vm._l(_vm.session.teams, function(team) {
                    return _c(
                      "div",
                      _vm._l(_vm.teamUsers(team), function(user) {
                        return _c("user-avatar", {
                          attrs: {
                            user: user,
                            title: user.first_name + " " + user.last_name
                          }
                        })
                      }),
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "row mt-5" },
                  _vm._l(_vm.session.games, function(game, index) {
                    return _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "row  justify-content-center" },
                        [
                          _c("span", { staticClass: "badge badge-light" }, [
                            _vm._v("GAME " + _vm._s(index + 1))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "row justify-content-around" },
                        _vm._l(game.total, function(total) {
                          return _c(
                            "div",
                            {
                              staticClass:
                                "rounded border border-light py-3 px-5",
                              staticStyle: { "background-color": "white" }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(total.score) +
                                  "\n              "
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  }),
                  0
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("ul", { staticClass: "list-unstyled col-6" }, [
              _c("li", [_vm._v("Sport: " + _vm._s(_vm.session.sport))]),
              _c("li", [_vm._v("Gametype: " + _vm._s(_vm.session.game_type))]),
              _c("li", [_vm._v("Approved: " + _vm._s(_vm.session.approved))]),
              _c("li", [
                _vm._v("Games played: " + _vm._s(_vm.session.games.length))
              ]),
              _c("li", [
                _vm._v("Created at: " + _vm._s(_vm.session.created_at))
              ])
            ])
          ]),
          _c("div", { staticClass: "row my-2" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [_vm._v("Players:")]),
                _c(
                  "ul",
                  { staticClass: "list-group list-group-flush" },
                  _vm._l(_vm.players, function(player) {
                    return _c(
                      "li",
                      { staticClass: "list-group-item" },
                      [
                        player.organizer
                          ? _c("i", {
                              staticClass: "fa fa-star",
                              attrs: { title: "organizer" }
                            })
                          : _vm._e(),
                        _c("profile-link", { attrs: { profile: player } })
                      ],
                      1
                    )
                  }),
                  0
                )
              ])
            ]),
            _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [_vm._v("Teams:")]),
                _c(
                  "ul",
                  { staticClass: "list-group list-group-flush" },
                  _vm._l(_vm.session.teams, function(team) {
                    return _c("li", { staticClass: "list-group-item" }, [
                      team.approved
                        ? _c("i", {
                            staticClass: "fa fa-thumbs-up",
                            attrs: { title: "Approved" }
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.teamNames(team)) +
                          ": " +
                          _vm._s(_vm.statusPastToCurrent(team.statusText)) +
                          "\n            "
                      ),
                      _c(
                        "span",
                        { staticClass: "badge", attrs: { title: "wins" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.teamItem(_vm.session.wins_per_team, team.id)
                                .score
                            )
                          )
                        ]
                      )
                    ])
                  }),
                  0
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "row my-2" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", { staticClass: "card panel-default" }, [
                _c("div", { staticClass: "card-header" }, [
                  _c(
                    "h3",
                    [
                      _vm._v("Notifications:\n            "),
                      _c("toggle-eye", {
                        attrs: { value: _vm.notifications.length },
                        on: { toggle: _vm.getNotifications }
                      })
                    ],
                    1
                  )
                ]),
                _c("table", { staticClass: "table" }, [
                  _vm._m(0),
                  _c(
                    "tbody",
                    _vm._l(_vm.notifications, function(notification) {
                      return _c("tr", [
                        _c("td", [_vm._v(_vm._s(notification.id))]),
                        _c("td", [_vm._v(_vm._s(notification.title))]),
                        _c("td", [_vm._v(_vm._s(notification.category))]),
                        _c("td", [
                          _c("img", {
                            staticStyle: { "max-height": "4em" },
                            attrs: { src: notification.image }
                          })
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(notification.profile.user.first_name) +
                              " " +
                              _vm._s(notification.profile.user.last_name)
                          )
                        ])
                      ])
                    }),
                    0
                  )
                ])
              ])
            ])
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("td"),
        _c("td", [_vm._v("Title")]),
        _c("td", [_vm._v("Category")]),
        _c("td", [_vm._v("Image")]),
        _c("td", [_vm._v("To Profile")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }