<template>
  <div v-if="loaded">
    <show-header
      :image="profile.user.avatar.original"
      type="user"
      :title="`${profile.user.first_name} ${profile.user.last_name}`">
      <ul>
        <li title="age">{{profile.age}}</li>
        <li title="gender">{{ profile.user.gender }}</li>
      </ul>
    </show-header>

    <div class="row">
      <div class="col-lg-7">
        <div class="card shadow block">
          <div class="card-body">
            <h3 v-t="'views.dashboard.children.athlete.children.profile.patch_forms.personal_info.title'"></h3>

            <patch-form-group
              type="text"
              :form="personal_info"
              name="first_name"
              @patch="personalInfoPatch"></patch-form-group>

            <patch-form-group
              type="text"
              :form="personal_info"
              name="last_name"
              @patch="personalInfoPatch"></patch-form-group>

            <patch-form-group
              type="date"
              :form="personal_info"
              name="date_of_birth"
              @patch="personalInfoPatch"></patch-form-group>

            <patch-form-group
              type="email"
              :form="personal_info"
              name="email"
              @patch="personalInfoPatch"></patch-form-group>

            <patch-form-group
              type="password"
              :form="personal_info"
              name="password"
              @patch="personalInfoPasswordPatch"></patch-form-group>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-5">
        <div class="card shadow block">
          <div class="card-body">
            <h3>Stats</h3>

            <p>Punten: {{profile.points}}</p>

            <p v-for="(value, strength) in profile.player_strength">Speelsterkte {{strength}}: {{value}}</p>

            <p>Vitaliteitsscore: {{(profile.vitality || {}).percentage}} %</p>
            <p>Onbevestigde speelmomenten: {{profile.unapproved_sessions_count}}</p>

            <p>Niewsbrief: {{profile.newsletter}}</p>
            <p>Rankings: {{(profile.canteen_rankings||[]).join(',')}}</p>

            <p>Sporten: {{(rank.sports || []).map(sportName).join(', ')}}</p>

            <p>Rang: {{rank.rank}}</p>
          </div>
        </div>
      </div>

      <div
        class="col-lg-6"
        v-if="statistics.opponents">
        <div class="card shadow block">
          <div class="card-body">
            <h3>Favoriete spelers</h3>

            <p class="">Vaakst gespeeld tegen:
              <profile-link :profile="statistics.opponents.most_played"></profile-link>
            </p>
            <p class="">Vaakst gewonnen van:
              <profile-link :profile="statistics.opponents.most_won_from"></profile-link>
            </p>
            <p class="">Vaakst verloren van:
              <profile-link :profile="statistics.opponents.most_lost_from"></profile-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!--<div v-if="user">-->
<!--Name: {{user.first_name}} {{user.last_name}}-->
<!--<img :src="user.avatar['100x100']">-->

<!--bio: {{user.bio}}<br>-->
<!--credits: {{user.credits}}<br>-->
<!--email: {{user.email}}<br>-->
<!--gender: {{user.gender}}<br>-->


<!--city: {{user.city}}<br>-->
<!--age: {{user.age}}<br>-->
<!--birthday: {{user.date_of_birth}}<br>-->
<!--postal code: {{user.postal_code}}<br>-->
<!--points: {{user.points}}<br>-->
<!--ranking points: {{user.ranking_points}}<br>-->

<!--<div v-for="code in user.codes">-->
<!--Code: {{code}}-->
<!--</div>-->
<!--<div v-for="strength in user.player_strength">-->
<!--Strength: {{strength}}-->
<!--</div>-->
<!--<div v-for="location in user.locations">-->
<!--Location: {{location}}-->
<!--</div>-->

<!--&lt;!&ndash;<div v-for="spot_type in user.spot_types">&ndash;&gt;-->
<!--&lt;!&ndash;Spot Type: {{spot_type}}&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--<hr>-->
<!--</div>-->
<!--</template>-->

<script>
  import api from '@/data/Backend';
  import { backend } from '@/api';

  import personalInfoForm from '@/models/PersonalInfoForm.ts';

  import PatchFormGroup from '_sportvue/components/elements/PatchFormGroup';
  import PatchImage from '_sportvue/components/elements/PatchImage';
  import ShowHeader from '_sportvue/components/ShowHeader.vue';

  export default {
    data() {
      return {
        profile: {},
        statistics: {},
        uploading_image: false,
        loaded: false,
      };
    },

    beforeRouteUpdate(to, from, next) {
      this.loaded = false;

      this.profile = {};
      this.statistics = {};
      next();
      this.getData();
    },

    mounted() {
      this.getData();
    },

    methods: {
      getData() {
        api().getProfile(this.$route.params.id).then((response) => {
          this.profile = response;
          this.loaded = true;
        }).catch((e) => console.error(e));

        this.getStatistics();
      },

      getStatistics() {
        const profileId = this.$route.params.id;

        backend(this.$router).get(`/profiles/${profileId}/statistics?include=user`).then((response) => {
          this.statistics = response.data;
        }).catch((e) => {
          console.error(e);
        });
      },

      sportName(sport) {
        const names = {
          dart: 'darts',
          billiard: 'billiards',
          table_tennis: 'table tennis',
        };

        return names[sport] || sport;
      },

      personalInfoPatch(field) {

      },
      personalInfoPasswordPatch(field) {

      },

      profileImagePatch(image) {
        this.uploading_image = true;

        this.patch({ data: image })
          .then((done) => {
            if (done) {
              this.notify();
            }

            this.uploading_image = false;
          })
          .catch(() => {
            this.uploadError();
            this.uploading_image = false;
          });
      },
    },

    computed: {
      personal_info() {
        return personalInfoForm(this.profile.user);
      },
      rank() {
        return this.profile && this.profile.rank || {};
      },
    },
    components: {
      PatchFormGroup,
      PatchImage,
      ShowHeader,
    },
  };
</script>

<style
  scoped
  lang="scss">

</style>
