var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.location
    ? _c(
        "div",
        [
          _c("show-header", {
            attrs: {
              title: _vm.location.name,
              sub:
                "views.dashboard.children.location_admin.children.location_profile.sub"
            }
          }),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-6" }, [
              _c("div", { staticClass: "card shadow block" }, [
                _vm._m(0),
                _c(
                  "table",
                  { staticClass: "table" },
                  _vm._l(_vm.location.spots, function(spot) {
                    return _c("tr", [
                      _c(
                        "td",
                        [_c("qr-link", { attrs: { code: spot.code } })],
                        1
                      ),
                      _c("td", [
                        _vm._v(_vm._s(spot.name) + " " + _vm._s(spot.code))
                      ]),
                      _c("td", [_vm._v(_vm._s(spot.quality))]),
                      _c("td", [_vm._v(_vm._s(spot.sport))])
                    ])
                  }),
                  0
                )
              ])
            ]),
            _c("div", { staticClass: "col-lg-6" }, [
              _c("div", { staticClass: "card shadow block" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("h3", {
                      directives: [
                        {
                          name: "t",
                          rawName: "v-t",
                          value:
                            "views.dashboard.children.location_admin.children.location_profile.patch_forms.contactDataForm.title",
                          expression:
                            "'views.dashboard.children.location_admin.children.location_profile.patch_forms.contactDataForm.title'"
                        }
                      ]
                    }),
                    _c("patch-form-group", {
                      attrs: { type: "text", form: _vm.form, name: "name" },
                      on: { patch: _vm.contactPatch }
                    }),
                    _c("patch-form-group", {
                      attrs: { type: "text", form: _vm.form, name: "street" },
                      on: { patch: _vm.contactPatch }
                    }),
                    _c("patch-form-group", {
                      attrs: {
                        type: "text",
                        form: _vm.form,
                        name: "house_number"
                      },
                      on: { patch: _vm.contactPatch }
                    }),
                    _c("patch-form-group", {
                      attrs: {
                        type: "text",
                        form: _vm.form,
                        name: "postal_code"
                      },
                      on: { patch: _vm.contactPatch }
                    }),
                    _c("patch-form-group", {
                      attrs: { type: "text", form: _vm.form, name: "city" },
                      on: { patch: _vm.contactPatch }
                    }),
                    _c("patch-form-group", {
                      attrs: { type: "text", form: _vm.form, name: "latitude" },
                      on: { patch: _vm.contactPatch }
                    }),
                    _c("patch-form-group", {
                      attrs: {
                        type: "text",
                        form: _vm.form,
                        name: "longitude"
                      },
                      on: { patch: _vm.contactPatch }
                    }),
                    _c("patch-form-group", {
                      attrs: { type: "text", form: _vm.form, name: "type" },
                      on: { patch: _vm.contactPatch }
                    })
                  ],
                  1
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-7" }),
            _c("div", { staticClass: "col-lg-5" }, [
              _vm.social_info
                ? _c("div", { staticClass: "card shadow block" }, [
                    _c("div", { staticClass: "card-body" })
                  ])
                : _vm._e()
            ])
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-body" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("Spots")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }