<template>
    <div class="card">
        <div class="header">
            <h4 class="title">Batch aanmaken</h4>
        </div>
        <div class="card-body">
            <form>
                <div class="form-group">
                    <label class="col-sm-2 col-form-label">Naam</label>
                    <div class="col-md-10">
                        <input
                            v-model="form.name"
                            type="text"
                            name="name"
                            class="form-control border-input"
                            placeholder="Naam">
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-2 col-form-label">Aantal</label>
                    <div class="col-md-10">
                        <input
                            v-model="form.amount"
                            type="number"
                            name="amount"
                            step="1"
                            min="1"
                            placeholder="Aantal"
                            class="form-control border-input">
                    </div>
                </div>
                <div class="text-right">
                    <button
                        type="submit"
                        class="btn btn-success btn-fill" @click.prevent="submit">Opslaan
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import {adminBackend} from '../api'

    export default {
        data() {
            return {
                form: {
                    name: null,
                    amount: null
                }
            }
        },

        methods: {
            submit() {
                adminBackend().post('/batches', this.form).then(() => {
                    this.$router.push({name: 'stickers.batches'});
                })
            }
        }

    }
</script>

<style lang="scss">

</style>
