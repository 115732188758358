<template>
  <div>
    <h1 v-t="'views.dashboard.children.sport_admin.children.locations.index.title'"></h1>
    <h2 v-t="'views.dashboard.children.sport_admin.children.locations.index.sub'"></h2>

    <div class="card tabbed">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item">
            <router-link
              :to="{name: 'locations.index'}"
              :exact="true"
              active-class="active"
              class="nav-link">Locaties
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{name: 'spots.index', query: {type: 'active'}}"
              :exact="true"
              active-class="active"
              class="nav-link">Actieve spots
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{name: 'spots.index', query: {type: 'inactive'}}"
              :exact="true"
              active-class="active"
              class="nav-link">Inactieve spots
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{name: 'spots.index', query: {type: 'temporary'}}"
              :exact="true"
              active-class="active"
              class="nav-link">Tijdelijke spots
            </router-link>
          </li>
        </ul>
      </div>

      <router-view></router-view>
    </div>
  </div>

</template>

<script>
  export default {}
</script>

<style lang="scss" scoped>
</style>
