var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("h1", [_vm._v("Bazen")]),
      _c(
        "item-list",
        {
          attrs: {
            rows: _vm.items,
            loading: _vm.loading,
            route: "profiles.show",
            value: ""
          },
          on: { search: _vm.search, lazy: _vm.loadLazy },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("td", [_c("profile-set", { attrs: { profile: row } })], 1),
                  _c("td", [_vm._v(_vm._s(row.points))]),
                  _c("td", [_vm._v(_vm._s(row.user.email))]),
                  _c("td", [_vm._v(_vm._s(_vm._f("toDate")(row.created_at)))]),
                  _c("td", [
                    _vm._v(_vm._s(_vm._f("toDate")(row.last_session_at)))
                  ]),
                  _c("td", [_vm._v(_vm._s(row.total_sessions))])
                ]
              }
            }
          ]),
          model: {
            value: _vm.searchvalue,
            callback: function($$v) {
              _vm.searchvalue = $$v
            },
            expression: "searchvalue"
          }
        },
        [
          _c("template", { slot: "header" }, [
            _c("th", [_vm._v("Naam")]),
            _c("th", [_vm._v("Punten")]),
            _c("th", [_vm._v("Email")]),
            _c("th", [_vm._v("Geregistreerd op")]),
            _c("th", [_vm._v("Laatste speelmoment")]),
            _c("th", [_vm._v("Aantal speelmomenten")])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }