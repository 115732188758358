<template>
  <div v-if="location">
    <show-header
      :title="location.name"
      sub="views.dashboard.children.location_admin.children.location_profile.sub">
    </show-header>

    <div class="row">
      <div class="col-lg-6">
        <div class="card shadow block">
          <div class="card-body">
            <h3 class="card-title">Spots</h3>
          </div>
          <table class="table">
            <tr v-for="spot in location.spots">
              <td>
                <qr-link :code="spot.code"></qr-link>
              </td>
              <td>{{spot.name}} {{spot.code}}</td>
              <td>{{spot.quality}}</td>
              <td>{{spot.sport}}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card shadow block">
          <div class="card-body">
            <h3 v-t="'views.dashboard.children.location_admin.children.location_profile.patch_forms.contactDataForm.title'"></h3>
            <patch-form-group type="text" :form="form" name="name" @patch="contactPatch"></patch-form-group>

            <patch-form-group type="text" :form="form" name="street" @patch="contactPatch"></patch-form-group>

            <patch-form-group type="text" :form="form" name="house_number" @patch="contactPatch"></patch-form-group>

            <patch-form-group type="text" :form="form" name="postal_code" @patch="contactPatch"></patch-form-group>

            <patch-form-group type="text" :form="form" name="city" @patch="contactPatch"></patch-form-group>

            <patch-form-group type="text" :form="form" name="latitude" @patch="contactPatch"></patch-form-group>

            <patch-form-group type="text" :form="form" name="longitude" @patch="contactPatch"></patch-form-group>

            <patch-form-group type="text" :form="form" name="type" @patch="contactPatch"></patch-form-group>
          </div>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-lg-7">
      </div>
      <div class="col-lg-5">
        <div class="card shadow block" v-if="social_info">
          <div class="card-body"></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import api from '@/data/Backend';

  import PatchImage from '_sportvue/components/elements/PatchImage.vue';
  import PatchFormGroup from '_sportvue/components/elements/PatchFormGroup.vue';
  import ShowHeader from 'sportvue/src/components/ShowHeader';
  import LocationContact from '@/models/LocationContact';
  import {backend} from '@/api';

  import QrLink from 'sportvue/src/components/elements/QrLink';

  export default {
    data() {
      return {
        form: new LocationContact(),
        loaded: false,
        location: null,
        uploading_image: false,
        social_info: false,
      };
    },
    mounted() {
      this.getData();
    },

    methods: {
      contactPatch() {
        backend(this.$router)
          .patch(`/canteens/${this.$route.params.id}`, {location: this.form.getSaveData()})
          .then(() => {
            console.log('saved');
        });
      },
      getData() {
        api().getSportLocation(this.$route.params.id).then((response) => {
          this.form.assign({
              ...response.location,
              id: response.id
            });

          this.location = response;
        }).catch((e) => console.error(e));
      },
    },

    components:{
      ShowHeader,
      PatchImage,
      PatchFormGroup,
      QrLink,
    },
  }
</script>

<style lang="scss">

</style>
