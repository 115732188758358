var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _vm._m(0),
    _c("div", { staticClass: "card-body" }, [
      _c("form", [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "col-sm-2 col-form-label" }, [
            _vm._v("Naam")
          ]),
          _c("div", { staticClass: "col-md-10" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.name,
                  expression: "form.name"
                }
              ],
              staticClass: "form-control border-input",
              attrs: { type: "text", name: "name", placeholder: "Naam" },
              domProps: { value: _vm.form.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "name", $event.target.value)
                }
              }
            })
          ])
        ]),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "col-sm-2 col-form-label" }, [
            _vm._v("Aantal")
          ]),
          _c("div", { staticClass: "col-md-10" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.amount,
                  expression: "form.amount"
                }
              ],
              staticClass: "form-control border-input",
              attrs: {
                type: "number",
                name: "amount",
                step: "1",
                min: "1",
                placeholder: "Aantal"
              },
              domProps: { value: _vm.form.amount },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "amount", $event.target.value)
                }
              }
            })
          ])
        ]),
        _c("div", { staticClass: "text-right" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-success btn-fill",
              attrs: { type: "submit" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.submit($event)
                }
              }
            },
            [_vm._v("Opslaan\n                ")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("h4", { staticClass: "title" }, [_vm._v("Batch aanmaken")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }