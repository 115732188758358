var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value:
              "views.dashboard.children.sport_admin.children.sessions.index.title",
            expression:
              "'views.dashboard.children.sport_admin.children.sessions.index.title'"
          }
        ]
      }),
      _c("h2", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value:
              "views.dashboard.children.sport_admin.children.sessions.index.sub",
            expression:
              "'views.dashboard.children.sport_admin.children.sessions.index.sub'"
          }
        ]
      }),
      _vm.pagination.total > 0
        ? _c("h4", [
            _vm._v(
              _vm._s(
                _vm.$t("components.session_list.title", {
                  sessions: _vm.pagination.total
                })
              )
            )
          ])
        : _vm._e(),
      _c(
        "item-list",
        {
          attrs: {
            rows: _vm.items,
            loading: _vm.loading,
            searching: false,
            route: "sessions.show",
            value: ""
          },
          on: { lazy: _vm.loadLazy },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "td",
                    [_c("profile-set", { attrs: { profile: row.organizer } })],
                    1
                  ),
                  _c("td", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          [
                            row.spot.name || row.spot.code,
                            row.location.name
                          ].join(" - ")
                        ) +
                        "\n      "
                    )
                  ]),
                  _vm.isMultisport
                    ? _c("td", [_vm._v(_vm._s(row.sport))])
                    : _vm._e(),
                  _c("td", [_vm._v(_vm._s(_vm._f("timeAgo")(row.started_at)))]),
                  _c("td", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("toDate")(row.created_at)) +
                        "\n      "
                    )
                  ])
                ]
              }
            }
          ])
        },
        [
          _c("template", { slot: "header" }, [
            _c("th", [_vm._v("Organizer")]),
            _c("th", [_vm._v("Location")]),
            _vm.isMultisport ? _c("th", [_vm._v("Sport")]) : _vm._e(),
            _c("th", [_vm._v("Start")]),
            _c("th", [_vm._v("Created")])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }