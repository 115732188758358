









































import Component, {mixins} from 'vue-class-component';
import ItemList from 'sportvue/src/components/ItemList.vue';
import QrLink from 'sportvue/src/components/elements/QrLink.vue';

import api from '@/data/Backend';
import LazyLoading from '@/mixins/LazyLoading.mixin';
import MultiSportMixin from '@/mixins/MultiSport.mixin';

@Component({components: {
    ItemList,
    QrLink,
}})
export default class SpotsComponent extends mixins(LazyLoading, MultiSportMixin) {
  public mounted() {
    this.getData();
  }
  public beforeRouteUpdate(to: any, from: any, next: Function) {
      this.items = [];
      next();
      this.getData();
  }

  public getData(newPage?: number) {
    this.loading = true;

    this.loadData(api().getSpots(
        newPage || this.page,
        this.pagination.per_page,
        this.$route.query.type as string,
    ));
  }

}
