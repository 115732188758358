var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("navigation-bar", {
        attrs: { "visible-routes": _vm.routes },
        on: { logout: _vm.logout }
      }),
      _c(
        "page-header",
        {
          attrs: {
            roles: _vm.roles,
            "home-route": "home",
            "active-role": _vm.activeRole
          },
          on: { chooseRole: _vm.chooseRole }
        },
        [_c("img", { attrs: { src: _vm.logo, alt: "home", title: "home" } })]
      ),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          {
            staticClass: "container-fluid",
            staticStyle: { "padding-left": "10rem" }
          },
          [_c("router-view")],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }