<template>
  <div id="heatmap" style="height: 400px;"></div>
</template>

<script>
  const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

  const token = process.env.VUE_APP_MAPBOX_TOKEN;
  import {backend} from '@/api';

  export default {
    data() {
      return {
        map: null,
        zoom: 4
      }
    },
    mounted() {
      this.initializeMap();
    },
    methods: {
      clear() {
        // this.map.removeLayer('canteens');
        this.map.removeLayer('clusters-bg');
        this.map.removeLayer('cluster-count');
        this.map.removeSource('locations');
      },
      getData() {
        backend(this.$router).get('/canteens/map?level=' + (Math.round(this.zoom / 2.5) + 1)).then(({data}) =>{
          const features = data.map(function(item)  {
            return {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [item.centroid.location.lon, item.centroid.location.lat],
              },
              properties: item
            }
          });

          // this.map.addLayer({
          //   id: "canteens",
          //   type: "circle",
          //   source: "locations",
          //   filter: ['has', 'doc_count'],
          //   paint: {
          //     'circle-color': '#ff6600',
          //     'circle-radius': ["+", 20, ["*", ['get', 'doc_count'], 0.2]]
          //   },
          // });
          //
          //
          this.map.addSource('locations', {
            type: 'geojson',
            data: {
              type: "FeatureCollection",
              features: features,
            }
          });

          this.map.addLayer({
            id: 'clusters-bg',
            type: 'circle',
            source: 'locations',
            filter: ['has', 'doc_count'],
            paint: {
              'circle-radius': ["+", 10, ["*", ['get', 'doc_count'], 0.5]],
              'circle-opacity': 0.3,
              'circle-color': '#ff6600',
              'circle-stroke-width': ["+", 20, ["*", ['get', 'doc_count'], 0.7]],
              'circle-stroke-opacity': 0.1,
              'circle-stroke-color': '#ff6600'
            }
          });

          this.map.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'locations',
            filter: ['has', 'doc_count'],
            layout: {
              'text-font': ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
              'text-field': '{doc_count}',
              'text-size': 16
            },
            paint: {
              'text-color': '#ffffff'
            }
          });


          // this.map.addControl(new mapboxgl.FullscreenControl(), 'bottom-left');
        })
      },

      initializeMap() {
        let bounds = [
          [1.098647, 50.840484],
          [9.310615, 53.628101]
        ];

        mapboxgl.accessToken = token;

        const map = new mapboxgl.Map({
          container: 'heatmap',
          style: 'mapbox://styles/mapbox/light-v9',
          zoom: this.zoom,
          maxBounds: bounds,
          scrollZoom: true
        });

        this.map = map;
        this.map.addControl(new mapboxgl.NavigationControl({
          showCompass: false
        }), 'bottom-left');

        map.on('zoom', () => {
          const currentZoom = map.getZoom();

          console.log('currentZoom', currentZoom);

          if(Math.round(currentZoom) !== this.zoom) {
            console.log('zoom level changed, doing the clear/request')
            this.zoom = Math.round(currentZoom);
            this.clear();
            this.getData();
          }
        });


        map.on('load', () => {
          this.getData();
          // map.loadImage('images/maps/marker_unselected.png', function (error, image) {
          //   if (error) {
          //     throw error;
          //   }
          //   map.addImage('marker_unselected', image);
          //   map.addLayer({
          //     id: 'unclustered-point',
          //     type: 'symbol',
          //     source: 'locations',
          //     filter: ['!has', 'point_count'],
          //     layout: {
          //       'icon-image': 'marker_unselected',
          //       'icon-size': 0.5
          //     }
          //   });
          // });

          // map.on('click', 'clusters-bg', function (e) {
          //   map.flyTo({
          //     zoom: (map.getZoom() + 1)
          //   });
          // });

          // map.on('click', (e) => {
          //   let features = map.queryRenderedFeatures(e.point, {
          //     layers: ['unclustered-point'] // replace this with the name of the layer
          //   });
          //
          //   if (!features.length) {
          //     return;
          //   }
          //
          //   let properties = features[0].properties;
          //   axios.get('https://api.sportunity.club/v1/locations/' + properties.id + '?include=opening_hours,spots').then(result => {
          //     const lprops = result.data.success;
          //
          //     this.mapItemDetails = new MapItemDetails(
          //       lprops.images['100x100'],
          //       lprops.name,
          //       lprops.street,
          //       lprops.house_no,
          //       lprops.house_no_addition,
          //       lprops.opening_hours.find(o => o.day == new Date().getDay()),
          //       lprops.city,
          //       lprops.spots.length
          //     );
          //   })
          //
          //
          // });
        });
      },
    }


  }
</script>

<style lang="scss">

</style>
