<template>
  <div>
      <div class="pull-right my-2 mx-2">
          <router-link :to="{name: 'stickers.batches.create'}" class="btn btn-sm btn-primary">
              <i class="fa fa-plus-square"></i>  Nieuwe batch aanmaken
          </router-link>
      </div>
      <table class="table">
          <thead>
          <tr>
              <th></th>
              <th>Naam</th>
              <th>Aantal</th>
              <th>Status</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="batch in batches">
              <td>
                  <a :href="batch.download_link" v-if="batch.status === 'stopped'">
                      <i class="fa fa-download fa-2x"></i>
                  </a>
                  <button class="btn btn-link" v-else @click="retryBatch(batch)">
                      <i class="fa fa-refresh fa-2x"></i>
                  </button>
              </td>
              <td>{{ batch.name }}</td>
              <td>{{ batch.amount }}</td>
              <td>
                  <div class="progress" v-if="batch.status === 'running'">
                      <div class="progress-bar" role="progressbar"
                          :style="{width: progressToWidth(batch) + '%'}">
                          {{ progressText(batch.progress) }} / {{ batch.amount }}
                      </div>
                  </div>
                  <template v-else>
                  {{ batch.status_name }}
                      <span v-if="batch.error">
                          <i class="fa fa-question-circle" v-tooltip="batch.error"></i>
                      </span>
                  </template>
              </td>
          </tr>
          <tr v-if="!batches.length">
              <td colspan="7" align="center">
                  <span class="text-muted">Er zijn nog geen batches</span>
              </td>
          </tr>
          </tbody>
      </table>

      <div class="content text-center">
          {{ batches.links }}
      </div>
  </div>
</template>

<script>
    import {adminBackend} from '../api'

    export default {
      data() {
        return {
            batches: {},
        }
      },
      beforeRouteUpdate (to, from, next) {
          this.getData();
          next();
      },
      mounted() {
          this.getData();
      },
      methods: {
          retryBatch(batch) {
              adminBackend(this.$router).put('/batches/' + batch.id, {'status': 'restart'}).then(response => {
                  this.batches = response.data.batches.data;
              })
          },

          progressToWidth(batch) {
              return Math.round(batch.progress / batch.amount * 100)
          },
          progressText(progress) {
            return progress === 'stopped' ? 'voltooid' : progress;
          },
          getData() {
              adminBackend(this.$router).get('/batches').then(response => {
                  this.batches = response.data.batches.data;
              })
          }
      }
  }
</script>

<style lang="scss">

</style>
