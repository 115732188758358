var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "col-lg-7" },
        [
          _c("statistic-card-group", {
            attrs: {
              columns: 2,
              statistics: _vm.statistics,
              statistics_order: _vm.statistics_order
            }
          })
        ],
        1
      ),
      _c("session-chart", { staticClass: "col-12 my-5" }),
      _c(
        "div",
        { staticClass: "col-12" },
        [_vm._v("\n      locaties:\n      "), _c("heat-map")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }