import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class MultiSportMixin extends Vue {
  protected appSports = (process.env.VUE_APP_SPORTS || '').split(',');

  protected appName = process.env.VUE_APP_APP_ID || 'sportunity';

  public get isMultisport() {
    return this.appSports.length > 1;
  }
}
