var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "div",
        [
          _c(
            "show-header",
            {
              attrs: {
                image: _vm.profile.user.avatar.original,
                type: "user",
                title:
                  _vm.profile.user.first_name + " " + _vm.profile.user.last_name
              }
            },
            [
              _c("ul", [
                _c("li", { attrs: { title: "age" } }, [
                  _vm._v(_vm._s(_vm.profile.age))
                ]),
                _c("li", { attrs: { title: "gender" } }, [
                  _vm._v(_vm._s(_vm.profile.user.gender))
                ])
              ])
            ]
          ),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-7" }, [
              _c("div", { staticClass: "card shadow block" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("h3", {
                      directives: [
                        {
                          name: "t",
                          rawName: "v-t",
                          value:
                            "views.dashboard.children.athlete.children.profile.patch_forms.personal_info.title",
                          expression:
                            "'views.dashboard.children.athlete.children.profile.patch_forms.personal_info.title'"
                        }
                      ]
                    }),
                    _c("patch-form-group", {
                      attrs: {
                        type: "text",
                        form: _vm.personal_info,
                        name: "first_name"
                      },
                      on: { patch: _vm.personalInfoPatch }
                    }),
                    _c("patch-form-group", {
                      attrs: {
                        type: "text",
                        form: _vm.personal_info,
                        name: "last_name"
                      },
                      on: { patch: _vm.personalInfoPatch }
                    }),
                    _c("patch-form-group", {
                      attrs: {
                        type: "date",
                        form: _vm.personal_info,
                        name: "date_of_birth"
                      },
                      on: { patch: _vm.personalInfoPatch }
                    }),
                    _c("patch-form-group", {
                      attrs: {
                        type: "email",
                        form: _vm.personal_info,
                        name: "email"
                      },
                      on: { patch: _vm.personalInfoPatch }
                    }),
                    _c("patch-form-group", {
                      attrs: {
                        type: "password",
                        form: _vm.personal_info,
                        name: "password"
                      },
                      on: { patch: _vm.personalInfoPasswordPatch }
                    })
                  ],
                  1
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-5" }, [
              _c("div", { staticClass: "card shadow block" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("h3", [_vm._v("Stats")]),
                    _c("p", [_vm._v("Punten: " + _vm._s(_vm.profile.points))]),
                    _vm._l(_vm.profile.player_strength, function(
                      value,
                      strength
                    ) {
                      return _c("p", [
                        _vm._v(
                          "Speelsterkte " +
                            _vm._s(strength) +
                            ": " +
                            _vm._s(value)
                        )
                      ])
                    }),
                    _c("p", [
                      _vm._v(
                        "Vitaliteitsscore: " +
                          _vm._s((_vm.profile.vitality || {}).percentage) +
                          " %"
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "Onbevestigde speelmomenten: " +
                          _vm._s(_vm.profile.unapproved_sessions_count)
                      )
                    ]),
                    _c("p", [
                      _vm._v("Niewsbrief: " + _vm._s(_vm.profile.newsletter))
                    ]),
                    _c("p", [
                      _vm._v(
                        "Rankings: " +
                          _vm._s((_vm.profile.canteen_rankings || []).join(","))
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "Sporten: " +
                          _vm._s(
                            (_vm.rank.sports || [])
                              .map(_vm.sportName)
                              .join(", ")
                          )
                      )
                    ]),
                    _c("p", [_vm._v("Rang: " + _vm._s(_vm.rank.rank))])
                  ],
                  2
                )
              ])
            ]),
            _vm.statistics.opponents
              ? _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "card shadow block" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("h3", [_vm._v("Favoriete spelers")]),
                      _c(
                        "p",
                        {},
                        [
                          _vm._v("Vaakst gespeeld tegen:\n            "),
                          _c("profile-link", {
                            attrs: {
                              profile: _vm.statistics.opponents.most_played
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "p",
                        {},
                        [
                          _vm._v("Vaakst gewonnen van:\n            "),
                          _c("profile-link", {
                            attrs: {
                              profile: _vm.statistics.opponents.most_won_from
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "p",
                        {},
                        [
                          _vm._v("Vaakst verloren van:\n            "),
                          _c("profile-link", {
                            attrs: {
                              profile: _vm.statistics.opponents.most_lost_from
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              : _vm._e()
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }