var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "gmap-map",
        {
          ref: "sessionmap",
          staticStyle: { width: "100%", height: "400px" },
          attrs: { zoom: 12, options: _vm.mapOptions, center: _vm.center },
          on: { zoom_changed: _vm.zoomChanged }
        },
        _vm._l(_vm.locations, function(m, index) {
          return _c("gmap-marker", {
            key: index,
            attrs: { position: m.position, title: m.label, label: m.label }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }