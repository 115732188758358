import {backend} from '@/api';
import BaseModel from 'sportvue/src/models/BaseModel';

export default class LocationContact extends BaseModel {
    public api() {
        return backend();
    }

    public defaults() {
        return {
            id: null,
            name: '',
            street: '',
            house_number: '',
            postal_code: '',
            city: '',
            latitude: null,
            longitude: null,
            type: null,
        };
    }

    get labels() {
        return {
            name: 'form.name.label',
            street: 'form.street.label',
            house_number: 'form.house_number.label',
            postal_code: 'form.postal_code.label',
            city: 'form.city.label',
            latitude: 'form.latitude.label',
            longitude: 'form.longitude.label',
        };
    }

    get placeholders() {
        return {
        };
    }

    public routes() {
        return {
            // save: 'canteens/{id}',
        };
    }
}
