<template>
  <div>
    <div
      v-if="!activityStatistics"
      class="card block shadow">
      <div class="card-body">
        <div class="text-center col-lg-12">
          <moon-loader
            :loading="true"
            color="#4CAF50"
            class="d-inline-block"></moon-loader>
        </div>
      </div>
    </div>
    <div v-if="activityStatistics">
      <h4>
        <!--        <b-dropdown offset="-25%, 20px" class="header chart-filter" variant="link" @show="show = true" @hide="show = false">-->
        <!--          <template slot="button-content">-->
        <!--            <slot name="button-content"></slot>-->
        <!--            <h4 class="selected-filter" v-t="'components.sprint.location_chart.' + filter"></h4><arrow rotate="bottom" :show="show"></arrow>-->
        <!--          </template>-->
        <!--          <b-dropdown-item v-if="filter !== 'sessions'" @click="filter = 'sessions'">-->
        <!--            <span v-t="'components.sprint.location_chart.sessions'"></span>-->
        <!--          </b-dropdown-item>-->
        <!--          <b-dropdown-item v-if="filter !== 'registrations'" @click="filter = 'registrations'">-->
        <!--            <span v-t="'components.sprint.location_chart.registrations'"></span>-->
        <!--          </b-dropdown-item>-->
        <!--        </b-dropdown>-->

        <!--<select class="selection" v-model="filter">-->
        <!--<option value="sessions" v-t="'components.sprint.location_chart.sessions'"></option>-->
        <!--<option value="registrations" v-t="'components.sprint.location_chart.registrations'"></option>-->
        <!--</select>-->
      </h4>
      <div class="card block shadow">
        <div class="card-body">
          <div class="chart-navigation">
            <h3>{{ getMonth()|firstLetterUppercase }} {{year}}</h3>
            <div
              class="round-button"
              @click="previousMonth()">
              <arrow rotate="left"></arrow>
            </div>
            <div
              v-if="selectedMonth == currentMonth && year == currentYear"
              class="round-button"
              @click="notify()">
              <arrow rotate="right"></arrow>
            </div>
            <div
              v-else
              class="round-button"
              @click="nextMonth()">
              <arrow rotate="right"></arrow>
            </div>
          </div>
          <span
            class="visitors text-right"
            v-t="'components.sprint.location_chart.' + filter"></span>

          <chart
            :chartData="chart_data"
            :chartType="'number'"
            id="dashboard-chart"
            :colors="{borderColor: 'rgb(0,101,255)', backgroundColor: 'rgba(0,101,255, 0.1)'}"></chart>
          <span
            class="date"
            v-t="'components.sprint.location_chart.date'"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Chart from '_sportvue/components/elements/Chart';
  import { firstLetterUppercase } from '_sportvue/filters';
  import RoundButton from '_sportvue/components/elements/RoundButton';
  import { backend } from '@/api';

  import moment from 'moment'

  export default {
    data() {
      return {
        days: [],
        year: null,
        selectedMonth: this.$route.query.maand || new Date().getMonth() + 1,
        filter: this.$route.query.grafiek || 'sessions',
        show: false,
        activityStatistics: undefined,
      };
    },

    watch: {
      '$route'(to, from) {
        if (to.query.maand !== from.query.maand) {
          this.getStatistics();
        }
      }
    },
    props: {
      location_id: {
        type: Number,
        required: false,
      },
      stat: {
        type: String,
      }
    },
    computed: {
      chart_data() {
        const days = this.activityStatistics.map(d => d);

        return {
          labels: days.map(d => d.day),
          datasets: [{ data: days.map(d => d[this.filter.toString()]) }]
        };
      },
    },
    created() {
      this.currentYear = new Date().getFullYear();
      this.year = new Date().getFullYear();
      this.currentMonth = new Date().getMonth() + 1;
      this.getStatistics();
    },
    methods: {
      getMonth() {
        return moment().month(this.selectedMonth - 1).format('MMMM');
      },
      previousMonth() {
        this.selectedMonth--;
        if (this.selectedMonth == 0) {
          this.selectedMonth = 12;
          this.year--;
        }

        this.$router.push({ query: { maand: this.selectedMonth } });
      },
      nextMonth() {
        this.selectedMonth++;
        if (this.selectedMonth == 13) {
          this.selectedMonth = 1;
          this.year++;
        }

        this.$router.push({ query: { maand: this.selectedMonth } });
      },
      notify() {
        // this.$notify({
        //   group: 'dashboard',
        //   type: [this.$t('notifications.chart_month_selector.icon'), 'primary'],
        //   title: this.$t('notifications.chart_month_selector.title'),
        //   text: this.$t('notifications.chart_month_selector.text')
        // })
      },
      getStatistics() {
        backend(this.$router).get(`statistics/${this.year}/${this.selectedMonth}`).then(({ data }) => {
          this.activityStatistics = data;
        })
        // if (isNil(this.location_id)) {
        //   return sprint.get(`statistics/${year}/${month}`)
        //     .then(({data}) => commit('setActivityStatistics', data));
        //
        //   this.$store.dispatch('sportAdmin/getActivityStatistics', [this.year, this.selectedMonth])
        // } else {
        //   this.$store.dispatch('rink/getActivityStatistics', [this.location_id, this.year, this.selectedMonth])
        // }
      }
    },
    components: {
      Chart,
      RoundButton
    },
    filters: {
      firstLetterUppercase,
    },
  };
</script>

<style
  lang="scss"
  scoped>
  @import "~@/scss/palette";

  .chart-filter {

    .dropdown-toggle {

      > > > .selected-filter {
        margin-top: 0;
      }
    }

    .arrow {
      color: $primary;

      &:not(.show):hover,
      &.showing {
        color: $primary !important;
      }
    }
  }

  .chart-filter .dropdown-menu.show {
    top: 20px;
    overflow-y: auto !important;
    display: none;
  }

  .dropdown.header:hover .btn .icon.arrow {
    color: $primary;
  }

  .card {

    .card-body {

      span.visitors, span.date {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 600;
        display: block;
      }

      span.visitors {
        margin-bottom: 1.25rem;
      }

      span.date {
        margin-top: 1.25rem;
      }

      .chart-navigation {
        position: relative;

        h3 {
          display: inline-block;
          vertical-align: middle;
          margin-bottom: 0;
          font-weight: 600;
          color: $gray-900;
        }

        .round-button {
          vertical-align: middle;
          display: inline-block;
          border: 1px solid $gray-25;
          border-radius: 50%;
          margin-left: 0.625rem;
          transition: all .3s;
          overflow: hidden;

          &:hover {
            border-color: $gray-50;
          }

          &:first-child {
            margin-left: 1.25rem;
          }

          .icon.arrow {
            width: 3.75rem;
            height: 3.75rem;
            cursor: pointer;
          }
        }
      }
    }
  }
</style>
