import Form from 'sportvue/src/models/Form';
import {User} from '@/models/User';

const factory = (user: User) => new Form({
  date_of_birth: {
    value: user.date_of_birth,
    label: 'form.date_of_birth.label',
    placeholder: 'form.date_of_birth.placeholder',
  },
  email: {
    value: user.email,
    label: 'form.email.label',
    placeholder: 'form.email.placeholder',
  },
  first_name: {
    value: user.first_name,
    label: 'form.first_name.label',
    placeholder: 'form.first_name.placeholder',
  },
  last_name: {
    value: user.last_name,
    label: 'form.last_name.label',
    placeholder: 'form.last_name.placeholder',
  },
  new_password: {
    value: '',
    label: 'form.password.label',
    placeholder: 'form.password_new.placeholder',
  },
  old_password: {
    value: '',
    label: 'form.password.label',
    placeholder: 'form.password_current.placeholder',
  },
}, null);

export default factory;
