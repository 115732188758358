<template>
    <div class="card">
        <div class="content table-responsive table-full-width">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th>Locatie naam</th>
                        <th>Adres</th>
                        <th>Aantal</th>
                        <th>Aangevraagd op</th>
                        <th>Aangevraagd door</th>
                        <th v-if="sent">Verzonden op</th>
                        <th v-else></th>
                    </tr>
                </thead>
                <tbody>
                <tr v-for="request in requests">
                    <td>{{ request.location_name }}</td>
                    <td>
                        {{ request.street }} {{request.house_no}} <br>
                        {{ request.postal_code }} {{ request.city }}
                    </td>
                    <td>{{ request.amount }}</td>
                    <td>{{ request.created_at|toDate }}</td>
                    <td>{{ request.requester_name }} ({{request.requester_email}})</td>
                    <td v-if="sent">{{ request.send_at|toDate }}</td>
                    <td v-else>
                        <i class="fa-2x fa fa-envelope text-primary" @click="send(request)"></i>
                    </td>
                </tr>
                <tr v-if="requests.length === 0">
                    <td colspan="7" class="text-center">Geen aanvragen</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import api from '@/data/Backend';

    export default {
        data() {
            return {
                requests: [],
            }
        },
        beforeRouteUpdate(to, from, next) {
            this.getData();
            next();
        },
        mounted() {
            this.getData()
        },
        methods: {
            send(request) {
                api().setStickerSent(request.id, 1, 1).then(({items}) => {
                    this.requests = items;
                });
            },
            getData() {
                this.$nextTick(() => {
                    api().getStickers(this.sent).then(({items}) => {
                        this.requests = items;
                    })
                })
            }
        },
        computed: {
            sent() {
                return this.$route.params.type === 'sent';
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
