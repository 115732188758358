<template>
  <div class="page">
    <navigation-bar :visible-routes="routes" @logout="logout"></navigation-bar>

    <page-header
      :roles="roles"
      home-route="home"
      :active-role="activeRole"
      @chooseRole="chooseRole">
      <img :src="logo" alt="home" title="home">
    </page-header>

    <div class="content">
      <div class="container-fluid" style="padding-left: 10rem">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
  import NavigationBar from 'sportvue/src/components/NavigationBar.vue'
  import PageHeader from 'sportvue/src/components/PageHeader.vue'

  export default {
    props: ['logo'],

    data() {
      return {
        roles:['admin'],
        activeRole:{},

        routes: [
          {
            name: 'users',
            meta:{
              icon:'user-group'
            }
          },
          {
            name: 'sessions',
            meta:{
              icon:'synchronize-timeout'
            }
          },
          {
            name: 'ranking',
            meta:{
              icon:'list-bullets'
            }
          },
          {
            name: 'locations.index',
            meta: {
              icon: 'building'
            }
          },
          {
            to: {name: 'stickers.index', params: {type: 'requested'}},
            meta: {
              faicon: 'fa-qrcode'
            }
          }
        ]
      }
    },
    methods:{
      chooseRole(role) {
        console.log('should choose role');
      },
      logout() {
        localStorage.removeItem('api_token');
        this.$router.push({ name: 'login' });
      }
    },
    components:{
      NavigationBar,
      PageHeader
    }
  }
</script>

<style lang="scss">
  @import "~sportvue/src/scss/lib";

  .page {

    nav.horizontal {
      width: 100vw;
      bottom: 0;
      flex-direction: row;
      justify-content: space-around;
      background-color: #fff;

      height: 4rem;
      align-items: center;
      position: fixed;
      z-index: 101;
      display: flex;
      transition: all .5s;
      box-shadow: 0 -10px 15px rgba($gray-900, .05);
    }

    > .navigation-bar, > header {
      background-color: #fff;
    }

    > .navigation-bar, > header .inner {
      box-shadow: 0 0 10px rgba($gray-900, .05);
    }

    > .navigation-bar {
      position: fixed;
      width: $navigation-bar--width;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 100;

      nav {
        width: 3.75rem;
        display: flex;
        flex-direction: column;
        margin: $navigation-header--height 0;

        a {

          &.logout {
            position: absolute;
            bottom: 1.875rem;
          }

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    > header {
      width: 100%;
      height: $navigation-header--height;
      position: fixed;
      top: 0;
      z-index: 101;
      display: flex;
      transition: all .5s;

      .logo {
        width: $navigation-bar--width;
        height: 100%;
        border-right: 1px solid $gray-50;
        box-sizing: content-box;
        flex: 0 0 $navigation-bar--width;
        z-index: 1;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .image {
          width: 40px;

          img {
            width: 100%;
            height: auto;
          }
        }
      }

      .inner {
        height: 100%;
        flex: 1;
        display: flex;
        align-items: center;

        .user {
          position: absolute;
          right: 60px;
          padding-left: 60px;
          border-left: 1px solid $gray-50;

          .dropdown-item {
            display: flex;
            align-items: center;

            .round-image {
              width: 2.5rem;
              height: 2.5rem;
              margin-right: 20px;
            }
          }
        }
      }

      &.hide {
        top: -$navigation-header--height;
      }
    }

    > .content {
      width: 100%;
      display: inline-block;
      margin-bottom: 5rem;
      padding-top: 7.5rem;
    }
  }
</style>
