





































import api from '@/data/Backend';

import ProfileSet from 'sportvue/src/components/elements/ProfileSet.vue';
import ItemList from 'sportvue/src/components/ItemList.vue';
import Component, {mixins} from 'vue-class-component';
import MultiSportMixin from '@/mixins/MultiSport.mixin';
import LazyLoadingMixin from '@/mixins/LazyLoading.mixin';

@Component({
    components: {
        ProfileSet, ItemList,
    },
})
export default class Sessions extends mixins(LazyLoadingMixin, MultiSportMixin) {
    public mounted() {
        this.getData();
    }

    public getData(newPage?: number) {
        this.loading = true;
        this.loadData(api().getSessions(newPage || this.page, this.pagination.per_page));
    }
}
