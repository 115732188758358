var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "pull-right my-2 mx-2" },
      [
        _c(
          "router-link",
          {
            staticClass: "btn btn-sm btn-primary",
            attrs: { to: { name: "stickers.batches.create" } }
          },
          [
            _c("i", { staticClass: "fa fa-plus-square" }),
            _vm._v("  Nieuwe batch aanmaken\n        ")
          ]
        )
      ],
      1
    ),
    _c("table", { staticClass: "table" }, [
      _vm._m(0),
      _c(
        "tbody",
        [
          _vm._l(_vm.batches, function(batch) {
            return _c("tr", [
              _c("td", [
                batch.status === "stopped"
                  ? _c("a", { attrs: { href: batch.download_link } }, [
                      _c("i", { staticClass: "fa fa-download fa-2x" })
                    ])
                  : _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        on: {
                          click: function($event) {
                            return _vm.retryBatch(batch)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-refresh fa-2x" })]
                    )
              ]),
              _c("td", [_vm._v(_vm._s(batch.name))]),
              _c("td", [_vm._v(_vm._s(batch.amount))]),
              _c(
                "td",
                [
                  batch.status === "running"
                    ? _c("div", { staticClass: "progress" }, [
                        _c(
                          "div",
                          {
                            staticClass: "progress-bar",
                            style: { width: _vm.progressToWidth(batch) + "%" },
                            attrs: { role: "progressbar" }
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.progressText(batch.progress)) +
                                " / " +
                                _vm._s(batch.amount) +
                                "\n                    "
                            )
                          ]
                        )
                      ])
                    : [
                        _vm._v(
                          "\n                " +
                            _vm._s(batch.status_name) +
                            "\n                    "
                        ),
                        batch.error
                          ? _c("span", [
                              _c("i", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: batch.error,
                                    expression: "batch.error"
                                  }
                                ],
                                staticClass: "fa fa-question-circle"
                              })
                            ])
                          : _vm._e()
                      ]
                ],
                2
              )
            ])
          }),
          !_vm.batches.length ? _c("tr", [_vm._m(1)]) : _vm._e()
        ],
        2
      )
    ]),
    _c("div", { staticClass: "content text-center" }, [
      _vm._v("\n        " + _vm._s(_vm.batches.links) + "\n    ")
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _c("th", [_vm._v("Naam")]),
        _c("th", [_vm._v("Aantal")]),
        _c("th", [_vm._v("Status")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "7", align: "center" } }, [
      _c("span", { staticClass: "text-muted" }, [
        _vm._v("Er zijn nog geen batches")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }