var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.activityStatistics
      ? _c("div", { staticClass: "card block shadow" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "text-center col-lg-12" },
              [
                _c("moon-loader", {
                  staticClass: "d-inline-block",
                  attrs: { loading: true, color: "#4CAF50" }
                })
              ],
              1
            )
          ])
        ])
      : _vm._e(),
    _vm.activityStatistics
      ? _c("div", [
          _c("h4"),
          _c("div", { staticClass: "card block shadow" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("div", { staticClass: "chart-navigation" }, [
                  _c("h3", [
                    _vm._v(
                      _vm._s(_vm._f("firstLetterUppercase")(_vm.getMonth())) +
                        " " +
                        _vm._s(_vm.year)
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "round-button",
                      on: {
                        click: function($event) {
                          return _vm.previousMonth()
                        }
                      }
                    },
                    [_c("arrow", { attrs: { rotate: "left" } })],
                    1
                  ),
                  _vm.selectedMonth == _vm.currentMonth &&
                  _vm.year == _vm.currentYear
                    ? _c(
                        "div",
                        {
                          staticClass: "round-button",
                          on: {
                            click: function($event) {
                              return _vm.notify()
                            }
                          }
                        },
                        [_c("arrow", { attrs: { rotate: "right" } })],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "round-button",
                          on: {
                            click: function($event) {
                              return _vm.nextMonth()
                            }
                          }
                        },
                        [_c("arrow", { attrs: { rotate: "right" } })],
                        1
                      )
                ]),
                _c("span", {
                  directives: [
                    {
                      name: "t",
                      rawName: "v-t",
                      value: "components.sprint.location_chart." + _vm.filter,
                      expression: "'components.sprint.location_chart.' + filter"
                    }
                  ],
                  staticClass: "visitors text-right"
                }),
                _c("chart", {
                  attrs: {
                    chartData: _vm.chart_data,
                    chartType: "number",
                    id: "dashboard-chart",
                    colors: {
                      borderColor: "rgb(0,101,255)",
                      backgroundColor: "rgba(0,101,255, 0.1)"
                    }
                  }
                }),
                _c("span", {
                  directives: [
                    {
                      name: "t",
                      rawName: "v-t",
                      value: "components.sprint.location_chart.date",
                      expression: "'components.sprint.location_chart.date'"
                    }
                  ],
                  staticClass: "date"
                })
              ],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }