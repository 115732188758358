export function getExport(backend, url, file) {
    return backend.get(url, {
        responseType: 'blob',
        headers: {
            Accept: 'text/csv',
        },
    }).then((response) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.setAttribute('download', file);
        document.body.appendChild(link);
        link.click();
    });
}
