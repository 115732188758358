var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card text-center tabbed" }, [
    _c("div", { staticClass: "card-header" }, [
      _c("ul", { staticClass: "nav nav-tabs card-header-tabs" }, [
        _c(
          "li",
          { staticClass: "nav-item" },
          [
            _c(
              "router-link",
              {
                staticClass: "nav-link",
                attrs: {
                  to: { name: "stickers.index", params: { type: "requested" } },
                  exact: true,
                  "active-class": "active"
                }
              },
              [_vm._v("Aangevraagd\n        ")]
            )
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "nav-item" },
          [
            _c(
              "router-link",
              {
                staticClass: "nav-link",
                attrs: {
                  to: { name: "stickers.index", params: { type: "sent" } },
                  exact: true,
                  "active-class": "active"
                }
              },
              [_vm._v("Verzonden\n        ")]
            )
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "nav-item" },
          [
            _c(
              "router-link",
              {
                staticClass: "nav-link",
                attrs: {
                  to: { name: "stickers.batches" },
                  "active-class": "active"
                }
              },
              [_vm._v("Batches\n        ")]
            )
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "card-body" }, [_c("router-view")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }