<template>
  <div v-if="session.id">
    <show-header
      :image="(location.images || {}).original"
      :title="session.started_at|toHumanDayDate">
      <ul>
        <li title="spot" v-if="spot.name">{{spot.name}}</li>
        <li title="location">
          <template v-if="location">{{location.name}}</template>
          <template v-else><i>Onbekende Locatie</i></template>
        </li>

        <li title="start - end">{{ session.started_at|toTime }}
          <span v-if="session.ends_at"> - {{ session.ends_at|toTime }} uur</span>
        </li>
      </ul>
    </show-header>

    <div class="row">
      <div class="card col-12">
        <div class="card-body">
          <div class="row justify-content-around">
            <div v-for="team in session.teams">
              <user-avatar v-for="user in teamUsers(team)" :user="user" :title="user.first_name + ' ' + user.last_name"></user-avatar>
            </div>
          </div>

          <div class="row mt-5">
            <div v-for="(game, index) of session.games" class="col-12">
              <div class="row  justify-content-center">
                <span class="badge badge-light">GAME {{index + 1}}</span>
              </div>
              <div class="row justify-content-around">
                <div v-for="total in game.total" class="rounded border border-light py-3 px-5" style="background-color: white">
                  {{total.score}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <ul class="list-unstyled col-6">
        <li>Sport: {{session.sport}}</li>
        <li>Gametype: {{session.game_type}}</li>
        <li>Approved: {{session.approved}}</li>
        <li>Games played: {{session.games.length}}</li>
        <li>Created at: {{session.created_at}}</li>
      </ul>
    </div>

    <div class="row my-2">
      <div class="col-sm-6">
        <div class="card">
          <div class="card-header">Players:</div>
          <ul class="list-group list-group-flush">
            <li
              v-for="player in players"
              class="list-group-item">
              <i
                class="fa fa-star"
                title="organizer"
                v-if="player.organizer"></i>
              <profile-link :profile="player"></profile-link>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="card">
          <div class="card-header">Teams:</div>
          <ul class="list-group list-group-flush">
            <li
              v-for="team in session.teams"
              class="list-group-item">
              <i
                class="fa fa-thumbs-up"
                v-if="team.approved"
                title="Approved"></i>
              {{teamNames(team)}}: {{statusPastToCurrent(team.statusText)}}
              <span
                class="badge"
                title="wins">{{teamItem(session.wins_per_team, team.id).score}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row my-2">
      <div class="col-sm-12">
        <div class="card panel-default">
          <div class="card-header">
            <h3>Notifications:
              <toggle-eye
                :value="notifications.length"
                @toggle="getNotifications"></toggle-eye>
            </h3>
          </div>
          <table class="table">
            <thead>
            <tr>
              <td></td>
              <td>Title</td>
              <td>Category</td>
              <td>Image</td>
              <td>To Profile</td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="notification in notifications">
              <td>{{notification.id}}</td>
              <td>{{notification.title}}</td>
              <td>{{notification.category}}</td>
              <td><img
                :src="notification.image"
                style="max-height: 4em"></td>
              <td>{{notification.profile.user.first_name}} {{notification.profile.user.last_name}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '@/data/Backend';
  import UserAvatar from 'sportvue/src/components/elements/UserAvatar';

  import ToggleEye from 'sportvue/src/components/elements/ToggleEye';
  import ShowHeader from 'sportvue/src/components/ShowHeader.vue';

  export default {
    components: { UserAvatar, ToggleEye, ShowHeader },
    data() {
      return {
        showGames: true,
        session: {},
        notifications: [],
      };
    },
    beforeRouteUpdate(to, from, next) {
      this.session = {};
      this.notifications = [];
      next();
      this.getData();
    },
    mounted() {
      this.getData();
    },

    computed: {
      players() {
        return [
          Object.assign({}, (this.session.organizer || {}), { organizer: true }),
        ].concat((this.session.participants || []));
      },

      location() {
        return this.session.location || {};
      },

      spot() {
        return this.session.spot || {};
      },
    },

    methods: {
      getData() {
        api().getSession(this.$route.params.id).then((response) => {
          this.session = response;

          console.log(this.session);
          console.log(this.session.games);
        }).catch((e) => {
          console.error(e);
        });
      },

      getNotifications() {
        if (this.notifications.length) {
          this.notifications = [];
          return;
        }

        api().getSessionNotifications(this.$route.params.id).then((response) => {
          this.notifications = response.items;
        }).catch((e) => {
          console.error(e);
        });
      },

      sessionUser(id) {
        const profile = (this.players || []).find((participant) => {
          return participant.id === id;
        });

        return profile ? profile.user : {};
      },

      teamItem(items, id) {
        return (items || []).find((item) => {
          return item.team_id === id;
        }) || {};
      },

      teamNames(team) {
        return team.profiles.map((item) => {
          if (item && item.user) {
            return item.user;
          }

          return this.sessionUser(item.id);
        }).map((user) => {
          return user.first_name + ' ' + user.last_name;
        }).join(',');
      },

      teamUsers(team) {
        return team.profiles.map((item) => {
          return item.user;
        });
      },
      statusPastToCurrent(status) {
        const statusses = {
          won: 'winning',
          lost: 'losing',
          draw: 'equal',
          unknown: 'unkown',
        };

        return statusses[status] || status;
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
