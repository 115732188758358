<template>
    <div>
        <gmap-map
            :zoom="12"
            @zoom_changed="zoomChanged"
            :options="mapOptions"
            :center="center"
            style="width:100%;  height: 400px;"
            ref="sessionmap">

            <gmap-marker
                :key="index"
                v-for="(m, index) in locations"
                :position="m.position"
                :title="m.label"
                :label="m.label">
            </gmap-marker>
        </gmap-map>
    </div>
</template>

<script>
    import { gmapApi } from 'vue2-google-maps';

    export default {
        props: {
            items: {
                type: Array,
                required: true,
            },
        },

        methods: {
            zoomChanged() {
                const sesmap = this.$refs.sessionmap;

                sesmap.$mapPromise.then((map) => {
                    const bounds = map.getBounds();

                    const tr = bounds.getNorthEast();
                    const bl = bounds.getSouthWest();

                    this.$emit('search', `${tr.lat()},${tr.lng()}|${bl.lat()},${bl.lng()}`);
                });
            },

            fitbounds(coordinates) {
                const sesmap = this.$refs.sessionmap;

                return sesmap && sesmap.$mapPromise.then((map) => {
                    const bounds = new this.google.maps.LatLngBounds();

                    coordinates.forEach(point => {
                        bounds.extend(point);
                    });

                    map.fitBounds(bounds);

                    return bounds.getCenter();
                })
            }
        },
        computed: {
            google: gmapApi,

            locations() {
                return this.items.map((item) => {
                    return {
                        position: {
                            lat: item.location.latitude,
                            lng: item.location.longitude,
                        },
                    };
                });
            },
            mapOptions() {
                return {};
            }
        },
        data() {
            return {
                center: {
                    lat: 52.092876, lng: 5.104480,
                    // lat: 52.209737, lng: 5.967078
                },
                nl: [{
                    lat: 50.803721015,
                    lng: 3.31497114423,
                }, {
                    lat: 53.5104033474,
                    lng: 7.09205325687,
                }]
            }
        },
        mounted() {
            // this.fitbounds(this.nl);
        },
    }
</script>

<style lang="scss">

</style>
